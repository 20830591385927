import React, {Component} from "react"
import { graphql} from "gatsby"
import { dateFormat } from "../utils/dateFormat"
import Layout from "../components/layout"




class newsItemTemplate extends Component {

  render() {
      const currentItem = this.props.data.wordpressWpNewsItems
      return (
      <Layout language={this.props.pageContext.language}>
        <p>{ dateFormat(currentItem.date) }</p>
        <h1 dangerouslySetInnerHTML={{__html: currentItem.title}}/>
        <div dangerouslySetInnerHTML={{__html: currentItem.content}}/>
      </Layout>
      )
  }
}

export default newsItemTemplate

export const pageQuery = graphql`
query NewsItemQueryEn($id: String!) {
    wordpressWpNewsItems(id: { eq: $id }){
    title
    content
    date
  }
}
`