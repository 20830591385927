

export const dateFormat = date =>{
    date = new Date(date)
    const months = [
        "janúar",
        "febrúar",
        "mars",
        "apríl",
        "maí",
        "júní",
        "júlí",
        "ágúst",
        "september",
        "október",
        "nóvember",
        "desember"
    ]
    return date.getDate() + ". " + months[date.getMonth()] + " " + date.getFullYear()
}